import { BaseEntityModel, BaseModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@common-src/model/form-control';
import { GroupQueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { reviewResultFilter, reviewResultList } from '@common-src/filter/review-result';
import { GroupEntityType, ReviewResult } from '@common-src/model/enum';
import WorkorderGroupService from '@common-src/service/workorder-group';
export class KnowledgeEntityModel extends BaseEntityModel {
    @FormControl({
        label: '标题',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    title: string = undefined;

    @FormControl({
        label: '作者',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    author: string = undefined;

    // @FormControl({
    //     label: '内容',
    //     type: FormControlType.TEXT_RICH,
    //     layout: { labelCol: 5, wrapperCol: 16, width: '100% ' }
    // } as FormControlTextModel)
    // content: string = `<p>😃🙂🙌<br/></p><p><b>加粗文字</b></p><p><b><u>下划线</u></b></p><p><b><u style="background-color: rgb(194, 79, 74);">带有背景色的文字</u></b></p><blockquote><p>这里是标题</p></blockquote><table border="0" width="100%" cellpadding="0" cellspacing="0"><tbody><tr><th>这里是表格</th><th></th></tr><tr><td><br/></td><td></td></tr></tbody></table>`;

    @FormControl({
        label: '内容',
        type: FormControlType.TEXT_AREA,
        layout: { labelCol: 5, wrapperCol: 16, width: '100% ' },
        rows: 9
    } as FormControlTextModel)
    content: string = '';

    @FormControl({
        label: '附件',
        type: FormControlType.UPLOAD,
        listType: 'text',
        maxFileNumber: 1
    } as FormControlUploadModel)
    attachmentList: Array<any> = undefined;
    attachment: string = undefined;

    @FormControl({
        label: '分组',
        type: FormControlType.TREE_SELECT,
        required: true,
        optionsPromise: new WorkorderGroupService(GroupEntityType.KNOWLEDGE).getGroupTree,
        optionsPromiseParam: GroupEntityType.KNOWLEDGE
    } as FormControlOptionModel)
    groupId: string = undefined;

    // status: ReviewResult = undefined;

    static getTableColumns() {
        return [
            {
                title: '标题',
                dataIndex: 'title'
            },
            {
                title: '作者',
                dataIndex: 'author'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            // {
            //     title: '发布时间',
            //     dataIndex: 'createdTime2'
            // },
            // {
            //     title: '状态',
            //     dataIndex: 'status',
            //     customRender: (text, record, index) => {
            //         return reviewResultFilter(text);
            //     }
            // },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (this.attachment) {
            const list = _.split(this.attachment, '/');
            this.attachmentList = [
                {
                    url: this.attachment,
                    type: 'file',
                    name: list[list.length - 1]
                }
            ];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (data.attachmentList) {
            data.attachment = _.get(data.attachmentList, '[0].url');
        }
        return data;
    }
}

export class KnowledgeStatusModel extends BaseModel {
    @FormControl({
        label: '审核结果',
        type: FormControlType.RADIO_GROUP,
        options: reviewResultList,
        required: true
    } as FormControlOptionModel)
    status: string = undefined;

    @FormControl({
        label: '审核意见',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    advice: string = undefined;
}

export class KnowledgeQueryModel extends GroupQueryModel {
    // @QueryControl({
    //     label: '状态',
    //     type: QueryControlType.SELECT,
    //     options: reviewResultList,
    //     hasAllOption: true
    // })
    // status: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '标题',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        data.groupIds = this.GroupIds;
        return data;
    }
}
